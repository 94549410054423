import React, { useState } from "react";
import VideoCard from "../../components/VideoCard/videoCard";
import styles from "./videos.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";


const Videos = (props) => {
  const [query, setQuery] = useState("");

  return (
    <div className={styles.videosContainer}>
      <h1 className={styles.title}>VİDEOLAR</h1>
      

      <div className={styles.searchBar}>
         <div>
          <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlass} />
          <input
            type="search"
            placeholder="Type to search videos..."
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>

      <div className={styles.list}>
        {props.videoList
          .filter(video => (video.snippet.title.toLowerCase().includes(query.toLowerCase()) || video.snippet.description.toLowerCase().includes(query.toLowerCase())) ? video : null)
          .map((video, index) => <VideoCard key={index} video={video} />)}
      </div>
    </div>
  );
};

export default Videos;
