import React from 'react'
import styles from "./videoCard.module.css"
const VideoCard = (props) => {
    let link = "/videoDetail/" + props.video.id;
    return (
        <a className={styles.link} href={link}>
            <div className={styles.videoContainer}>
                <div className={styles.leftContainer}>
                    <img src={props.video.snippet.thumbnails.medium.url} alt={props.video.snippet.title} />
                </div>
                <div className={styles.rightContainer}>
                    <h3>{props.video.snippet.title}</h3>
                    <p>{props.video.snippet.description}</p>

                </div>
            </div>
        </a>


    )
}

export default VideoCard