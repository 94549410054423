import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.leftContainer}>
        <h3>Menü</h3>
        <nav>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : null)}
                to="/"
              >
                Anasayfa
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : null)}
                to="/bio"
              >
                Biyografi
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : null)}
                to="/videos"
              >
                Videolar
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : null)}
                to="/publications"
              >
                Yayınlar
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : null)}
                to="/faq"
              >
                S.S.S.
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : null)}
                to="/contact"
              >
                İletişim
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      <div className={styles.centerContainer}>
        <p>Dr. Halit Yaşar</p>
        <div className={styles.socialIcons}>
          <a href="https://twitter.com/yasarhalit1979" target="_blank">
            <FontAwesomeIcon className={styles.icon} icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com/uzm.dr.halityasar" target="_blank">
            <FontAwesomeIcon className={styles.icon} icon={faInstagram} />
          </a>
          <a href="https://www.youtube.com/channel/UCg6Cq1wXkGZ8qu0_DWhpszw" target="_blank">
            <FontAwesomeIcon className={styles.icon} icon={faYoutube} />
          </a>
        </div>
      </div>

      <div className={styles.rightContainer}>
        <h3>İletişim</h3>
        <div className={styles.contactElement}>
          <FontAwesomeIcon className={styles.contactIcon} icon={faLocationDot} />
          <p>Kurtuluş Mahallesi Karahanlılar Sokak No:13 Alaşehir/Manisa</p>
        </div>

        <div className={styles.contactElement}>
        <FontAwesomeIcon className={styles.contactIcon} icon={faEnvelope} />
          <a href="mailto:yasarhalit@gmail.com">yasarhalit@gmail.com</a>
        </div>

        <div className={styles.contactElement}>
        <FontAwesomeIcon className={styles.contactIcon} icon={faPhone} />
          <p>444 50 66</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
