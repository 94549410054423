import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styles from "./contactForm.module.css";
import { useState } from "react";

const ContactForm = () => {
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast.success("Mesajınız gönderildi!", {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };
  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
      };
      setLoader(true);

      await emailjs.send(
        "service_7n4o1d9",
        "template_43kf3y7",
        templateParams,
        "MVFPSnawBvd2z9wmO"
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  console.log(loader);
  return (
    <div className={styles.container}>
      <h2 className={styles.title} dataoutline="iletişim formu">
        iletişim formu
      </h2>

      <form
        className={styles.form}
        id="contact-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className={styles.firstRow}>
          <div className={styles.name}>
            <input
              type="text"
              name="name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Lütfen isminizi yazın",
                },
                maxLength: {
                  value: 30,
                  message: "Lütfen 30 karakteri aşmayın",
                },
              })}
              placeholder="İsim"
            ></input>
            {errors.name && (
              <span className={styles.errorMessage}>{errors.name.message}</span>
            )}
          </div>
          <div className={styles.email}>
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              placeholder="E-posta adresi"
            ></input>
            {errors.email && (
              <span className={styles.errorMessage}>
                Geçerli bir e-posta adresi yazın
              </span>
            )}
          </div>
        </div>

        <div className={styles.secondRow}>
          <div className={styles.subject}>
            <input
              type="text"
              name="subject"
              {...register("subject", {
                required: {
                  value: true,
                  message: "Lütfen konuyu yazın",
                },
                maxLength: {
                  value: 75,
                  message: "Lütfen 75 karakteri aşmayınız",
                },
              })}
              placeholder="Konu"
            ></input>
            {errors.subject && (
              <span className={styles.errorMessage}>
                {errors.subject.message}
              </span>
            )}
          </div>
        </div>

        <div className={styles.thirdRow}>
          <div className={styles.message}>
            <textarea
              rows={3}
              name="message"
              {...register("message", {
                required: true,
              })}
              placeholder="Mesajınız..."
            ></textarea>
            {errors.message && (
              <span className={styles.errorMessage}>
                Lütfen bir mesaj yazın
              </span>
            )}
          </div>
        </div>

        <button disabled={loader} className={styles.submitBtn} type="submit">
          Gönder
        </button>
      </form>

      <ToastContainer />
    </div>
  );
};
export default ContactForm;
