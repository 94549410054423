import React from "react";
import ContactForm from "../../components/ContactForm/contactForm";
import styles from "./contact.module.css";

const Contact = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.contactInfo}>
          <h3>Dr. Halit Yaşar</h3>
          <p>
            Adres: Özel Sarıkız Hastanesi Kurtuluş, Karahanlılar Sokak No: 13,
            45600 Alaşehir/Manisa
          </p>
          <p>Telefon: 444 50 66</p>
          <p>E-posta: yasarhalit@gmail.com</p>
        </div>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3128.7429443640312!2d28.521800615663622!3d38.3549312863404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b87bde696afe0d%3A0xc002b908c590bb4a!2zw5Z6ZWwgU2FyxLFrxLF6IEhhc3RhbmVzaQ!5e0!3m2!1str!2snl!4v1653426020578!5m2!1str!2snl"
          // width="600"
          // height="450"
          style={{ border: "1px solid black" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className={styles.splitter}></div>
      <div className={styles.rightContainer}>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
