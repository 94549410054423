import React from "react";
import styles from "./publications.module.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import rawPub from "../../assets/text/publications.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy
  } from "@fortawesome/free-solid-svg-icons";

const toastifySuccess = () => {
    toast.success("Kopyalandı!", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

function PubCard(props) {
    return (<div className={styles.card}>
        <h3 className={styles.pubTitle}>{props.p["Title"]}</h3>
        <div className={styles.info}>
            <div className={styles.leftP}>
                <p>{props.p["Publishers"]}</p>
            </div>
            <div className={styles.splitter}></div>

            <div className={styles.rightP}>
                <p>{props.p["Info"]}</p>

        <FontAwesomeIcon title="Copy" className={styles.icon} icon={faCopy} onClick={async () => {
                    await navigator.clipboard.writeText(props.p["Info"]);
                    toastifySuccess();
                }} />
                {/* TODO : Popup Copy text 
                <div className={styles.popup} onMouseEnter={(e) => {e.target.firstElementChild.classList.toggle("show"); }}>Click me to toggle the popup!
                    <span className={styles.popuptext} >A Simple Popup!</span>
                </div> */}
            </div>
        </div>

    </div>);

}

const Publications = () => {
    const aClassPubs = rawPub[0]
    const otherPubs = rawPub[1]

    return (
        <div className={styles.pubContainer}>
            <h1 className={styles.title} dataoutline="yayınlar">YAYINLAR</h1>
            <h2><u>A Sınıfı Dergilerde Yapılan Yayınlar</u></h2>
            <ul>
                {aClassPubs.map((p, i) => <PubCard key={i} p={p} />)}
            </ul>
            <h2><u>Diğer Dergilerde Yapılan Yayınlar</u></h2>
            <ul>
                {otherPubs.map((p, i) => <PubCard key={i} p={p} />)}
            </ul>
      <ToastContainer />
        </div>
    );
};

export default Publications;
