import React from "react";
import styles from "./sideMenu.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 faBars,
} from "@fortawesome/free-solid-svg-icons";

const SideMenu = (props) => (
  <>
    <FontAwesomeIcon icon={faBars} className={styles.icon} onClick={props.click} />
  </>


);

export default SideMenu;
