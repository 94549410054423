import React from "react";
import { useParams } from 'react-router-dom';
import styles from "./videoDetail.module.css"

function VideoDetailCard(props) {
  // console.log(props.video?.snippet);
  const videoEmbedSrc = "https://www.youtube.com/embed/" + props.video?.snippet.resourceId.videoId;
  const videoUrl = "https://www.youtube.com/watch?v=" + props.video?.snippet.resourceId.videoId;
  const title = props.video?.snippet.title
  const date = props.video?.snippet.publishedAt.split('T')[0]
  const description = props.video?.snippet.description
  return (
    <div>
      <div className={styles.youtubeVideoContainer}>
        <iframe 
          title="Youtube video frame"
          width="896" height="504"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          src={videoEmbedSrc}>
        </iframe>
      </div>
      <div className={styles.videoTexts}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.date}> {date}</p>
        <p className={styles.description}>  {description}</p>
        <p className={styles.info}>  Sorularınız ve yorumlarınız için <a href="/contact">iletişim formu</a> ve <a href={videoUrl} target="_blank" rel="noopener noreferrer">youtube</a> aracılığı ile bana ulaşabilirsiniz.</p>        
      </div>
    </div>
  )
}

const VideoDetail = (props) => {
  const { id } = useParams()
  const video = props.videoList.filter(video => video.id === id)[0]
  return (
    <div className={styles.videoDetailContainer}>
      <VideoDetailCard key={id} video={video} />

    </div>
  );
};

export default VideoDetail;
