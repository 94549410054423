import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Faq from "./container/Faq/faq";
import Bio from "./container/Bio/bio";
import Home from "./container/Home/home";
import Contact from "./container/Contact/contact";
import Videos from "./container/Videos/videos";
import Publications from "./container/Publications/publications";
import VideoDetail from "./container/VideoDetail/videoDetail";

import Layout from "./components/Layout/layout";

import { useEffect, useState } from "react";
import styles from "./navigator.module.css";


function Navigator() {
  const [videoList, setVideoList] = useState([]);

  function getUrl(key, pageToken) {
    const keys = {
      key1: "AIzaSyC_RBN6di6IxzNi3bQfQmkhsLNYnrpdEPs",
      key2: "AIzaSyB42TKuwsR59_gdmGAztflQIvHneWmBDwU"
    },
      playListID = "PLEu59wlkR5dEiXnwJuH6TAkDq0BqFWDXJ",
      pt = pageToken === undefined ? "" : `&pageToken=${pageToken}`,
      url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playListID}&key=${keys[key]}${pt}`;
    return url;
  }

  async function getData(key, pageToken) {
    const url = getUrl(key, pageToken);
    const response = await fetch(url);
    const data = await response.json();

    return data;
  }

  async function effectBuilder() {
    const lastData = [];
    let data, keyCounter = 1;

    do {
      data = await getData("key"+keyCounter, data ? data.nextPageToken : undefined);

      if (data.error) {
        if(keyCounter < 2) {
          keyCounter+=1;
          // console.log("keyCounter increased");
          continue;
        }
        // console.log("ERROR==========================");
        break;
      }
      
      lastData.push(data.items);
    } while (data.nextPageToken || data.error);

    
    let l = lastData.flat()
    for(let i = 0; i < l.length; i++){
      l[i].snippet.description = l[i].snippet.description.split('Kaynaklar')[0];
    }
    
    setVideoList(l);

  }

  useEffect(() => {
    effectBuilder();
  }, []);

 




  return (
    <div className={styles.container} lang="tr">
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home videoList={videoList} />} />
            <Route path="/bio" element={<Bio />} />
            <Route path="/videos" element={<Videos videoList={videoList} />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/videoDetail/:id" element={<VideoDetail videoList={videoList} />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default Navigator;
