import React from "react";
import Landing from "../../components/Landing/landing";
import VideoSlider from "../../components/VideoSlider/videoSlider";
import styles from "./home.module.css";

const Home = (props) => {
  return (
    <div className={styles.container}>
      <Landing />
      <VideoSlider videoList={props.videoList} />
    </div>
  );
};

export default Home;
