import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import BackDrop from "../BackDrop/backDrop";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import SideDrawer from "../SideDrawer/sideDrawer";
import styles from "./layout.module.css";

const Layout = ({ children }) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  function drawerToggleClickHandler() {
    setSideDrawerOpen((prevSideDrawerOpen) => !prevSideDrawerOpen);
  }

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <div className={styles.container}>
      <Header drawerToggleClickHandler={drawerToggleClickHandler} />
      <SideDrawer show={sideDrawerOpen} click={drawerToggleClickHandler} />
      {sideDrawerOpen && <BackDrop click={drawerToggleClickHandler} />}
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
