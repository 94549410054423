import React from "react";
import styles from "./bio.module.css";
import PortrePhoto from "../../assets/img/portre-photo.webp";

const Bio = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img src={PortrePhoto} alt="profile" />
        <div>
          <h1>Dr. Halit Yaşar</h1>
          <h2>Nöroloji Uzmanı</h2>
          <h2>Manisa / Türkiye</h2>
        </div>
      </div>
      <div className={styles.splitter}></div>
      <div className={styles.rightContainer}>
        <h1 dataoutline="biyografi">biyografi</h1>
        <p>
          1979’da Zonguldak’ta doğdu. 1996 yılında İstanbul Bahçelievler
          Lisesini bitirdi. 2002 yılında Ankara GATA Askeri Tıp Fakültesinden
          Tabip Teğmen olarak mezun oldu. Kıt’a görevini Kıbrıs 50. Mekanize
          Piyade Alayında 2003-05 yılları arasında yaptı. 2005-2010 yılları
          arasında GATA Haydarpaşa Eğitim ve Araştırma Hastanesinde Nöroloji
          Asistanlığını tamamladı. 2019 yılından bu yana
          Sarıkız Hastanesinde Nöroloji Uzmanı olarak çalışmaktadır. Evli ve 3
          çocuk babası olup iyi düzeyde İngilizce bilmektedir.
        </p>
        <h2 dataoutline="Yetenekleri ve İlgi Alanları">
          Yetenekleri ve İlgi Alanları
        </h2>
        <ul>
          <li>
            15 yıldır Eelektroensefalografi (EEG), Elektromiyografi (EMG) ve
            Polisomnografi (PSG, Uyku Testi).
          </li>
          <li>
            Migren ve diğer baş ağrılarında blokaj (GON blokajı) uygulamaları
          </li>
          <li>
            Migren ve hemifasiyal spazmda botulinum toksin (BOTOX) uygulamaları
          </li>
          <li>
            Vertigoda (Bening pozisyonel vertigo) manevra uygulama ve tedavileri
          </li>
          <li>
            Akut inmede (beyin damar tıkanıklığı) İ.V. tromboliz (damardan yoğun
            kan sulandırıcı) uygulamaları
          </li>
          <li>Nöroloji Yoğun Bakım </li>
          <li>
            Migren ve diğer baş ağrıları, epilepsi (sara) hastalığı, Parkinson
            ve Alzheimer hastalığı ve diğer bunamalar, sinir sıkışmaları,
            miyastenia gravis, beyin damar tıkanıklıkları, baş dönmeleri, uyku
            bozuklukları, el titremesi, hareket bozuklukları, nöropatik ağrı ve
            diğer nörolojik hastalıklar
          </li>
        </ul>
        <h2 dataoutline="Mesleki Deneyimler">Mesleki Deneyimler</h2>
        <ul>
          <li>2019- Özel Sarıkız Hastanesi, Manisa</li>
          <li>2019-2019 Gaziemir Özel Gazikent Tıp Merkezi, İzmir</li>
          <li>2016-2018 Salihli Özel Can Hastanesi, Manisa</li>
          <li>2010-2016 Nöroloji Uzmanı, Ankara Mevki Asker Hastanesi </li>
          <li>2008-2011 Acil Nöroloji Uzmanı, Acıbadem Maslak Hastanesinde </li>
          <li>
            2007-2010 Şişli ve Nişantaşı Görüntüleme Merkezlerinde EMG-EEG
            tetkiklerinin uygulanması{" "}
          </li>
          <li>
            2007-2009 Çorlu AS-TIP Nörofizyoloji merkezinde polisomnografi
            tetkiki uygulaması ve raporlanması
          </li>
          <li>
            2005-2009 GATA Haydarpaşa Eğitim Hastanesi Nöroloji Servisinde
            Nöroloji uzmanlık eğitimi{" "}
          </li>
          <li>
            2003-2005 KTBK, 50. Mekanize Piyade Alayı/Yılmazköy/KKTC’de
            pratisyen hekimlik
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Bio;
