import React from "react";
import { NavLink } from "react-router-dom";
import LandingPhoto from "../../assets/img/landing-photo.webp";
import styles from "./landing.module.css";

const Landing = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.leftContainer}>
          <p className={styles.name}>Dr. Halit Yaşar</p>
          <h1 className={styles.job}>Nöroloji Uzmanı</h1>
          <p className={styles.description}>
            Beyin, Kas ve Sinir Hastalıkları Uzmanı
          </p>

          <NavLink to="/contact" className={styles.firstButton}>
            İletişime Geçin
          </NavLink>
          <NavLink to="/faq" className={styles.secondButton}>
            Sıkça Sorulan Sorular
          </NavLink>
        </div> 

        <img
          className={styles.photo}
          src={LandingPhoto}
          alt="photo of the doctor"
        />
      </div>
    </div>
  );
};

export default Landing;
