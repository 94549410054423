import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";
import styles from "./videoSlider.module.css";

const VideoSlider = (props) => {
  return (
    <div className={styles.slider}>
      <div className={styles.mainContainer}>
        <h2 className={styles.title} dataoutline="videolar">
          videolar
        </h2>
        <Splide
          options={{
            breakpoints: {
              1200: {
                perPage: 2,
              },
              768: {
                autoPlay: true,
                perPage: 1,
              },
            },
            height: 420,
            type: "loop",
            perPage: 3,
            perMove: 1,
            pagination: false,
            gap: "1rem",
            padding: 0,
          }}
        >
          {props.videoList.map((video, index) => (
            <SplideSlide key={index} className={styles.container}>
              <div className={styles.card}>
                <div className={styles.imgBx}>
                  <img src={video.snippet.thumbnails.medium.url} alt="thumbnails" />
                </div>

                <div className={styles.contentBx}>
                  <h2>
                    {video.snippet.title.substr(0, 70)}
                    {video.snippet.title.length > 70 ? "..." : null}
                  </h2>
                  <p>
                    {video.snippet.description.substr(0, 100)}
                    {video.snippet.description.length > 100 ? "..." : null}
                  </p>
                </div>
                <a href={"/videoDetail/" + video.id}>Devamı</a>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default VideoSlider;
