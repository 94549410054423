import React from "react";
import styles from "./sideDrawer.module.css";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const SideDrawer = (props) => {
  return (
    <div>
      <nav className={classNames(styles.sideDrawer, props.show && styles.open)}>
        <h2 className={styles.title} dataoutline="menü">
          menü
        </h2>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/"
              onClick={props.click}
            >
              ANASAYFA
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/bio"
              onClick={props.click}
            >
              BİYOGRAFİ
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/videos"
              onClick={props.click}
            >
              VİDEOLAR
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/publications"
              onClick={props.click}
            >
              YAYINLAR
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/faq"
              onClick={props.click}
            >
              S.S.S.
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/contact"
              onClick={props.click}
            >
              İLETİŞİM
            </NavLink>
          </li>
        </ul>
        <div className={styles.socialMedia}>
          <a href="https://twitter.com/yasarhalit1979" target="_blank">
            <FontAwesomeIcon className={styles.icon} icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com/uzm.dr.halityasar" target="_blank">
            <FontAwesomeIcon className={styles.icon} icon={faInstagram} />
          </a>
          <a href="https://www.youtube.com/channel/UCg6Cq1wXkGZ8qu0_DWhpszw" target="_blank">
            <FontAwesomeIcon className={styles.icon} icon={faYoutube} />
          </a>
        </div>
      </nav>
    </div>
  );
};
export default SideDrawer;
