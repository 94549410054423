import React from "react";
import styles from "./faq.module.css";
import rawFaq from "../../assets/text/faq.json"


const FaqCard = (props) => {
  return <details>
      <summary>{props.p["Q"]}</summary>
      <div className={styles.faq_content}>
        <p>{props.p["A"]}</p>
      </div>
    </details>;
}

const Faq = () => {
  return (
    <div className={styles.faqContainer}>
      <h2>SIKÇA SORULAN SORULAR</h2>
      <ul>
        {/* <FaqCard p={props["faqList"][0]}></FaqCard> */}
        {rawFaq.map( (p,i) =>  <FaqCard key={i} p={p} />)}
      </ul>
    </div>
  );
};

export default Faq;
