import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./header.module.css";
import SideMenu from "../SideMenu/sideMenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Header = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.toggleBtn}>
        <SideMenu click={props.drawerToggleClickHandler} />
      </div>
      <div className={styles.leftContainer}> <a href="/">Dr. Halit Yaşar</a></div>
      <nav className={styles.centerContainer}>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/"
            >
              ANASAYFA
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/bio"
            >
              BİYOGRAFİ
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/videos"
            >
              VİDEOLAR
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/publications"
            >
              YAYINLAR
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/faq"
            >
              S.S.S.
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : null)}
              to="/contact"
            >
              İLETİŞİM
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={styles.rightContainer}>
        <a href="https://twitter.com/yasarhalit1979" target="_blank">
          <FontAwesomeIcon className={styles.icon} icon={faTwitter} />
        </a>
        <a href="https://www.instagram.com/uzm.dr.halityasar" target="_blank">
          <FontAwesomeIcon className={styles.icon} icon={faInstagram} />
        </a>
        <a href="https://www.youtube.com/channel/UCg6Cq1wXkGZ8qu0_DWhpszw"target="_blank">
          <FontAwesomeIcon className={styles.icon} icon={faYoutube} />
        </a>
      </div>
    </div>
  );
};

export default Header;
